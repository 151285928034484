import React from "react";
import styled from "styled-components";
import ng from "../assets/Images/viewers-national.png"
import disney from "../assets/Images/viewers-disney.png"
import ngvideo from "../assets/Videos/1564676296-national-geographic.mp4"
import marvel from "../assets/Images/viewers-marvel.png"
import mvideo from "../assets/Videos/marvel.mp4"
import dvideo from "../assets/Videos/1564674844-disney.mp4"
import star from  "../assets/Images/viewers-starwars.png"
import svideo from "../assets/Videos/1608229455-star-wars.mp4"
import pixar from  "../assets/Images/viewers-pixar.png"
import pvideo from "../assets/Videos/1564676714-pixar.mp4"
import cover from "../assets/Images/album-journey-alexander-precht-white.jpg"
import vf from "../assets/Images/vf.jpg"
import mw from "../assets/Images/mwcover.jpeg"
import sq from "../assets/Images/sq.jpeg"


const Viewers1 = (props) => {
  return (
    <Container className="Test12345">

      <h3>Latest Releases</h3>
      <img src={cover} alt="" />
      <img src={vf} alt="" />
      <img src={mw} alt="" />
      <img src={sq} alt="" />
      
    </Container>
  );
};

export default Viewers1;

const Container = styled.div`
  padding: 30px 0 26px;
  margin-top: 20px;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(5, minmax(0, 1fr));
img{
    width: 100px;
    height: 100px;
}
`;

const Wrap = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  border: 3px solid rgba(249, 249, 249, 0.3);
  box-shadow: rgb(0 0 0/69%) 0px 26px 30px -10px,
    rgb(0 0 0/73%) 0px 10px 16px -10px;
  transition: all 250ms;
  img {
    inset: 0px;
    top: 0;
    position: absolute;
    width: 100px;
    height: 100px;
    object-fit: cover;
    opacity: 1;
    z-index: 1;
    transition: opacity 500ms ease-in-out 0s;
    margin-left:-1px;
  }
  video {
    border-radius: 5px;
    object-fit: cover;
    top: 0;
    width: 100px;
    height: 100px;
    opacity: 0;
    z-index: 0;
  }

  &:hover {
    transform: scale(1.05);
    border: 3px solid rgba(249, 249, 249, 0.8);
    z-index: 1;

    img {
      background: none;
    }

    

    video {
      opacity: 1;
    }
  }
`;
