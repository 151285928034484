//react imports
import React, { useEffect, useState } from "react"

//bootstrap imports
import 'bootstrap/dist/css/bootstrap.min.css';
import './MusicGrid.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import SplitButton from 'react-bootstrap/SplitButton';

//modal component imports
import LicenseViewModal from "./modalComponents/LicenseViewModal"

//waveform component import
import Waveform from "./Waveform";


//icon import
import { HiDownload } from "react-icons/hi";
import { FiPlusSquare } from "react-icons/fi";

//Asset import
import playbutton from "../assets/icons/play.png"
import pausebutton from "../assets/icons/pause.png"


  

function MusicGrid(props){

  const [show, setShow] = useState(false);
  const [selectedSong, setSelectedSong] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (song) => {
    setShow(true);
    setSelectedSong(song)
  };

  const [show1, setShow1] = useState(false);
  const handleShow1 = (song) => {
    setShow1(true);
    setSelectedSong(song)
  };
  const handleClose1 = () => setShow1(false);

  const [show2, setShow2] = useState(false);
  const handleShow2 = (song) => {
    setShow2(true);
    setSelectedSong(song)
  };
  const handleClose2 = () => setShow2(false);
 

  const waveFormTriggerBridge = () => {
    props.waveformTriggerBridge()
  }
  


  const [showLicenseModal, setShowLicenseModal] = useState(false)
  const [modalData, setModalData] = useState([undefined, undefined, undefined])
  const showModal = (preisstufeValues) => {
    setModalData(preisstufeValues)
    setShowLicenseModal(true)
  }

  
  const selectSong = (index) => {

    console.log(index)
    //Check wether a new song is beeing played or the current song was started again
    if (index === props.trackIndex) {
      props.setIsPlaying()
    } else{
      if (props.isPlaying){
        props.setIsPlaying()
      }
      props.setTrackIndex(index)
    }
  }

  let moods = []
  let speeds = []
  let genres = []
  let albums = []
  let artists = []
  let productionQualities = []
  let performanceQualities = []
  let writingQualities = []

  props.activeTags.map(tagsData => {
    if (tagsData.type === "mood"){
      moods = [...moods, tagsData.value]
    } else if (tagsData.type === "speed"){
      speeds = [...speeds, tagsData.value]
    } else if (tagsData.type === "genre"){
      genres = [...genres, tagsData.value]
    } else if (tagsData.type === "productionQuality"){
      productionQualities = [...productionQualities, tagsData.value]
    } else if (tagsData.type === "performanceQuality"){
      performanceQualities = [...performanceQualities, tagsData.value]
    } else if (tagsData.type === "writingQuality"){
      writingQualities = [...writingQualities, tagsData.value]
    } else if (tagsData.type === "album"){
      albums = [...albums, tagsData.value]
    } else if (tagsData.type === "artist"){
      artists = [...artists, tagsData.value]
    } else {
      console.log("ERROR: Unknown tag type")
    }
  })

 

  useEffect(() => {
    props.musicData.map(song => {
      if (song.legacyID !== 0){
        const lowerCaseTitle = song.title.toLowerCase()
        const lowerCaseArtist = song.artist.toLowerCase()
        if (
          (moods.includes(song.mood) || moods.length === 0) &&
          (genres.includes(song.genre) || genres.length === 0) &&
          (albums.includes(song.album) || albums.length === 0) &&
          (artists.includes(song.artist) || artists.length === 0) &&
          // (productionQualities.includes(song.productionQuality) || productionQualities.length === 0) &&
          (lowerCaseTitle.includes(props.searchQuery.toLowerCase()) || lowerCaseArtist.includes(props.searchQuery.toLowerCase()) || props.searchQuery.length === 0)
          ){
            document.getElementById(song.id).style = ""
          } else {
            document.getElementById(song.id).style = "display: none;"
          }
      }
    })
  }, [props.activeTags, props.searchQuery])



  const songsRender = props.musicData.map(song => {

    // console.log("Song: ", song.preisstufen)
    if (song.legacyID !== 0){
      const lowerCaseTitle = song.title.toLowerCase()
      const lowerCaseArtist = song.artist.toLowerCase()

      // if (song.preisstufen !== undefined){
        // const PreisstufenDropdownItems = song.preisstufen.map(preisstufe => {
        //   // console.log("preisstufe: ", preisstufe.Name)
        //   return(
        //     <Dropdown.Item as="button"><div onClick={(e) => this.changeValue(e.target.textContent)}>{preisstufe.Name}</div></Dropdown.Item>
        //   )
        // })
      // }
      
      return(
        <div style={props.variableStyles} className="MusicGridListItem row mb-14" key={song.id} id={song.id}>
          {/* <h1 id="status"></h1> */}
          
          <div className="Albumcover col-auto">
            <img src={song.coverIMG}></img>
          </div>
          <div className="Songname col-2">
            <h6>{song.title}</h6>
            <p onClick={()=> handleShow1(song)}>{song.artist}</p>

            <Modal className="my-modal" class="modal-dialog modal-lg" show={show1} onHide={handleClose1}>
              <Modal.Header closeButton>
                <Modal.Title><h4>Artist Page</h4></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Im Aufbau</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleClose1}>Close!</Button>
              </Modal.Footer>
            </Modal>
          </div>

          <div className="ProgressArea col">
            <Waveform onFinishTrigger={waveFormTriggerBridge} audio={song.src} setIsPlaying={props.setIsPlaying} isPlaying={props.isPlaying} songTitle={song.title} songArtist={song.artist} songCover={song.coverIMG} setTrackProgress={props.setTrackProgress} setTrackInfo={props.setTrackInfo} trackInfo={props.trackInfo} trackProgressTarget={props.trackProgressTarget}/>
          </div>
          
          <div className="InteractionArea col-auto">
            <a>
              <button type="button" className="SaveButton" onClick={()=> handleShow(song)} ><FiPlusSquare/>Details</button> 
      
              <Modal className="my-modal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title><h4>SONGDETAILS</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Titelname: {selectedSong?.title}</p>
                  <p>Artist: {selectedSong?.artist}</p>
                  <p>Album: {selectedSong?.album}</p>
                  <p>Songwriter: {selectedSong?.Songwriter}</p>
                  <p>Key: {selectedSong?.key}</p>
                  <p>Speed: {selectedSong?.speed}</p>
                  <p>Record Label: {selectedSong?.recordlabel}</p>
                  <p>Publisher: {selectedSong?.publisher}</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={()=> {handleShow1(song)}}>Artist wählen</Button>
                  <Modal className="my-modal" class="modal-dialog modal-lg" show={show1} onHide={handleClose1}>
                    <Modal.Body>
                      <img src={selectedSong?.banner} className="img-fluid" ></img><br/>
                      <h4>Artistinfo "{selectedSong?.artist}"</h4>
                      <p>Der Multiinstrumentalist und Sänger Mazze Wiesner macht musikalisch keine Kompromisse. Die tiefgängigen Dichtungen seiner Alben leben durch Wiesner`s ausdrucksstark rauchige Stimme. In Symbiose mit kraftvoller Rockmusik stechen seine Songs aus der Masse hervor. „Dank WIESNER braucht man sich um die Zukunft qualitativ hochwertiger deutscher Musik keine Sorgen zu machen.“- Deutsche Mugge</p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="primary" onClick={handleClose1}>Zurück</Button>
                    </Modal.Footer>
                  </Modal>
                  <Button variant="primary" onClick={handleClose}>Close!</Button>
                </Modal.Footer>
              </Modal>
            </a> 
            <a href={song.download} download={song.title} target='_blank'>
              <button type="button" onClick={()=> showModal(song.preisstufen)} className="DownloadButton"><HiDownload/>Lizensierung</button>

              

              {/* <Modal className="my-modal" class="modal-dialog modal-lg" show={show2} onHide={handleClose2}>
                <Modal.Header closeButton>
                  <Modal.Title>
                      <SplitButton variant="outline-secondary" title={selectedPriceGroup} pullRight id="split-button-pull-right">
                        <Dropdown.Item as="button"><div onClick={() => {setSelectedPriceGroup("Preisstufe 1")}}>Preisstufe 1</div></Dropdown.Item>
                      </SplitButton>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h4>Produktbeschreibung</h4>
                  <p>{song.beschreibung}</p><br/>
                  <h5>Preis:  {selectedSong?.title === "Was Vor Uns Liegt" ? selectedPriceGroup === "Preisstufe 1" ? "299€" : "499€" : selectedSong?.preisstufe1}</h5>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={handleClose1}>Kaufen!</Button>
                </Modal.Footer>
              </Modal> */}
            </a>
          </div>  
        </div>
      )
    }
  })

  return(
    <div className="MusicGrid container">
      <LicenseViewModal modalData={modalData} isVisible={showLicenseModal} setIsVisible={setShowLicenseModal}/>
      {songsRender}
    </div>
  )
}
export default MusicGrid

