import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// import { auth, signInWithEmailAndPassword, signInWithGoogle } from "./firebase";
import { getAuth, signInWithEmailAndPassword, signInWithGoogle } from "firebase/auth";

import { useAuthState } from "react-firebase-hooks/auth";
import "./componentStyles/login.css";
import amboss from "../assets/icons/amboss.png"
import logo from "../assets/icons/favicone.png"

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const auth = getAuth();

  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/main");
  }, [user, loading]);
  return (

      
    <div className="login">
      <div className="login__name">
        <img src={logo}></img>
        </div>
        <div className="login__name">
        <h3>Unique Music Group</h3>
        </div>
       
      <div className="login__container">
        <input
          type="text"
          className="login__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          className="login__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button
          className="login__btn"
          onClick={() => signInWithEmailAndPassword(auth, email, password)}
        >
          Login
        </button>
        {/* <button className="login__btn login__google" onClick={signInWithGoogle}>
          Login with Google
        </button> */}
        {/* <div>
          <Link to="/reset">Forgot Password</Link>
        </div> */}
        <div className="linkText">
          Don't have an account? <Link to="/register">Register</Link> now.
        </div>
      </div>
    </div>
  )
}
export default Login;
