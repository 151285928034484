// React imports
import React, { useEffect } from "react"
import { useState } from "react"

//Database import
import {db} from "./firebase"

//Firebase imports
import {collection, getDocs, query,limit, orderBy} from "firebase/firestore"
import amboss from "../assets/icons/MW_Bild.jpg"
import amboss1 from "../assets/icons/mw.jpg"
import unique from "../assets/icons/favicone.png"



//CSS Import
import './Test.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//react component imports
import SideBar from "./SideBar"
import Slider from "./slider"
import IconBar from "./IconBar"
import Viewers from "./Viewers"
import Viewers1 from "./Viewers1"
import MusicGrid from "./MusicGrid"

import MusicPlayer from "./MusicPlayer"


import ProfileButton from "./ProfileButton";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function Startseite(){


  const [show, setShow] = useState(false);
  const [selectedSong, setSelectedSong] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (song) => {
    setShow(true);
    setSelectedSong(song)
  };

  const [show1, setShow1] = useState(false);
  const handleShow1 = (song) => {
    setShow1(true);
    setSelectedSong(song)
  };
  const handleClose1 = () => setShow1(false);

  const [show2, setShow2] = useState(false);
  const handleShow2 = (song) => {
    setShow2(true);
    setSelectedSong(song)
  };
  const handleClose2 = () => setShow2(false);
 


    //code snipped from wavesurfer as reference
  //setTrackInfo([songTitle, songArtist, songCover, waveSurfer.getDuration()])

  //state for musicplayer
  const [isPlaying, setIsPlaying] = useState(false)
  const [trackIndex, setTrackIndex] = useState(0)
  const [trackInfo, setTrackInfo] = useState(["--", "--", "https://firebasestorage.googleapis.com/v0/b/finntorben-f5af3.appspot.com/o/gray.png?alt=media&token=1d90004b-727f-497f-b3aa-f703e757b8d5", 0])
  const [trackProgress, setTrackProgress] = useState(0)
  const [trackProgressTarget, setTrackProgressTarget] = useState(0) //used for setting current track progress to a target value

  //event listener for switching isPlaying when the spacebar is pressed
  document.addEventListener("keyup", e => {
    if (e.code === "Space"){
      console.log("spacebarhit")
    }
  })
  // console.log(trackInfo)

  //state for in-app navigation
  const [navState, setNavState] = useState("Library")
  // const [viewAreaHeadline, setViewAreaHeadline] = useState("Library")

  //state for tags
  const [activeTags, setActiveTags] = useState([])

  //state for search queries
  const [searchQuery, setSearchQuery] = useState("")

  //state for musicdata from the database
  const [musicData, setMusicData] = useState([])

  //state for storing window height
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  //collecting data from database
  const musicCollectionRef = collection(db, "MazzeSongs")
  //queries to be used for retrieving data
  // const firstQuery = query(musicCollectionRef, orderBy("legacyId"), limit(Math.ceil(windowHeight / 90)))
  // const firstQuery = query(musicCollectionRef, orderBy("legacyId"), limit(50))
  const firstQuery = query(musicCollectionRef, orderBy("legacyId"), limit(100))

  //store the number of documents received from the database
  const [numOfDocs, setNumOfDocs] = useState(0)

  //check for the window beeing resized. data used to query size needed to fill the screen
  window.addEventListener("resize", () => {
    setWindowHeight(window.innerHeight)
  })

  //function for getting songs from the db
  const getMusic = async () => {
    // console.log("getting music")
    // let selectedQuery

    // if (numOfDocs === 0){

    //   console.log("first query")
    //   selectedQuery = firstQuery
      
    // } else {

    //   console.log("additional query")

    // }
    const data = await getDocs(firstQuery)
    //const data = await getDocs(selectedQuery)
    setMusicData(data.docs.map(doc => ({...doc.data(), id: doc.id})))
    setNumOfDocs(data.docs.length)
  }

  //calling the function on first load of the app
  useEffect(() => {
    getMusic()
  },[])

  // console.log("music data: ", musicData)

  const switchPlaying = () => {
    setIsPlaying(!isPlaying)
  }

  const switchPlayingFalse = () => {
    setIsPlaying(false)
  }

  const newTrackIndex = (index) => {
    setTrackIndex(index)
  }

  const waveformTriggerBridge = () => {
    setIsPlaying(false)
  }

  return (
    <div className="container-fluid gx-0 h-100">
      <div className="Mainframe row gx-0">
      <div className="IconBarContainer col-auto col-md-3">
          <IconBar />
        </div>
        {/* <div className="SideBarContainer col-auto col-md-3">
          <SideBar numOfDocs={numOfDocs} searchQuery={searchQuery} setSearchQuery={setSearchQuery} musicData={musicData} activeTags={activeTags} setActiveTags={setActiveTags} navInput={setNavState} navState={navState}/>
        </div> */}
        <div className="ViewArea col">
          <div className="viewAreaHeader row">
            <div className="artist row"></div>
           
              <div className="container2 row md-3">
                 {/* <img src={amboss} onClick={()=> {handleShow1()}}/>
                <Modal className="my-modal" class="modal-dialog modal-lg" show={show1}>
          
        <Modal.Body>
       
       
        <img src={amboss1} className="img-fluid" ></img>
       <h4><br/>Mazze Wiesner</h4><p>
       Der Multiinstrumentalist und Sänger Mazze Wiesner macht musikalisch keine Kompromisse. Die tiefgängigen Dichtungen seiner Alben leben durch Wiesner`s ausdrucksstark rauchige Stimme. In Symbiose mit kraftvoller Rockmusik stechen seine Songs aus der Masse hervor. „Dank WIESNER braucht man sich um die Zukunft qualitativ hochwertiger deutscher Musik keine Sorgen zu machen.“- Deutsche Mugge</p>
      
      
        
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose1}>
            Zurück
          </Button>
        </Modal.Footer>
      </Modal> */}
                {/* <h5>Mazze Wiesner</h5>  */}
                <Slider />
                <Viewers />
                <Viewers1 /> 
                
                      
               
                <div className="container2 col md-3">
  
  </div>
  
              </div> 
            </div>
          </div>
         
       </div>
      
      {/* <MusicPlayer isPlaying={isPlaying} setIsPlaying={switchPlaying} trackIndex={trackIndex} setTrackIndex={newTrackIndex} trackProgress={trackProgress} setTrackProgress={setTrackProgress} setTrackProgressTarget={setTrackProgressTarget} trackInfo={trackInfo}/> */}
    </div>
  );
};

export default Startseite