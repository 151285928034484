import React, {useRef, useEffect, useState} from 'react'
import WaveSurfer from 'wavesurfer.js'
import PropTypes from 'prop-types' 

//icon import
import { FaPlay, FaPause } from "react-icons/fa";
import { withTheme } from 'styled-components';
import mazze from "../assets/icons/mazze.mp3"
import mazze1 from "../assets/icons/mazze.json"



function Waveform({onFinishTrigger, audio, songTitle, songArtist, songCover, setTrackProgress, setTrackInfo, trackInfo, isPlaying, setIsPlaying, trackProgressTarget}) {
  const containerRef = useRef()
  const waveSurferRef = useRef({
    isPlaying: () => false,
  })
  const [isPlayingLocal, setIsPlayingLocal] = useState(false)
  const [readableSongDuration, setReadableSongDuration] = useState([0, 0])

  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      container: containerRef.current,
      responsive: true,
      cursorWidth: 0,
      barWidth:0,
      barHeight: 1,
      progressColor: "#fff",
      hideScrollbar: true,
      barRadius: 3,
      waveColor: 'white',
      progressColor: 'rgba(221,104,0)',
      normalize: true,
    
    })
  
    waveSurfer.load(audio)
   
    waveSurfer.on('ready', () => {
      let readableSeconds = parseInt(waveSurfer.getDuration() % 60)
      let readableMinutes = parseInt((waveSurfer.getDuration() / 60) % 60)

      if (readableSeconds < 10){
        readableSeconds = 0 + "" + readableSeconds
      }

      setReadableSongDuration([readableMinutes, readableSeconds])
      waveSurferRef.current = waveSurfer
    })
    //listeners for synching song info with MusicPlayer component
    waveSurfer.on('play', () => {
      setTrackInfo([songTitle, songArtist, songCover, waveSurfer.getDuration()])
      setTrackProgress(0)
    })
    waveSurfer.on('finish', () => {
      onFinishTrigger()
      setIsPlayingLocal(waveSurferRef.current.isPlaying())
      setTrackProgress(waveSurfer.getCurrentTime())
    })
    
    waveSurfer.on('seek', () => {
      // console.log("scrolling..., isplaying: ", isPlaying)
    })

    //variable used for comparison in IF
    var lastTime = 0
    waveSurfer.on('audioprocess', () => {
      if (waveSurfer.isPlaying()) {
        //IF code used to update the track progress state in APP every second
        const currentTime = waveSurfer.getCurrentTime()    
        if (Math.round(currentTime) !== lastTime || lastTime === undefined){
          setTrackProgress(currentTime)
          lastTime = Math.round(currentTime)
        }
      }
    })

    return () => {
      waveSurfer.destroy()
    }
  }, [audio])

  useEffect(() => {
    if (waveSurferRef.current.isPlaying() && songTitle !== trackInfo[0]){
      playPause()
    }
  },[trackInfo])

  useEffect(() => {
    if (!isPlaying && waveSurferRef.current.isPlaying()){
      waveSurferRef.current.pause()
      setIsPlayingLocal(false)
    } else if (isPlaying && trackInfo[0] === songTitle) {
      waveSurferRef.current.play()
      setIsPlayingLocal(true)
    }
  },[isPlaying])

  useEffect(() => {
    if (trackInfo[0] === songTitle){
      waveSurferRef.current.seekTo(trackProgressTarget / trackInfo[3])
    }
  }, [trackProgressTarget])

  const playPause = () => {
    waveSurferRef.current.playPause()
    setIsPlaying()
    // setIsPlaying(waveSurferRef.current.isPlaying())
    setIsPlayingLocal(waveSurferRef.current.isPlaying())
  }

  // let readableDurationSeconds
  // let readableDurationMinutes

  // if (waveSurferRef.current.getDuration() >= 0){
  //   readableDurationSeconds = parseInt(waveSurferRef.current.getDuration() % 60)
  //   readableDurationMinutes = parseInt((waveSurferRef.current.getDuration() / 60) % 60)
  // }

  // console.log(waveSurferRef.current.getCurrentTime())


 

  return (
    <div className='progressContainer row'>
      <div className='playPauseContainer col-auto'>
        {isPlayingLocal ? <button onClick={playPause}><FaPause/></button> : <button onClick={playPause}><FaPlay/></button>}
      </div>
      <div className='waveformContainer col' ref={containerRef} />
      <div className='waveformSongDuration col-auto'>
        <p>{readableSongDuration[0]}:{readableSongDuration[1]}</p>
        {/* <p>10:10</p> */}
      </div>
    </div>
  )
}

Waveform.propTypes = {
  audio: PropTypes.string.isRequired,
}
export default Waveform