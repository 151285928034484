import logo from "../assets/icons/favicone.png"

const artists = {
    "Rock": [
      {
        name: "Mazze Wiesner",
        songs: "Deuschrock",
        url:
          "https://bafybeihjpvrgqlrke32ovnqc3jhzm4fmt5qjdvwmd7qt6oedvjzxujyuay.ipfs.w3s.link/mw.jpeg"
      },
      {
        name: "April Art",
        songs: "Alternative Rock",
        url:
          "https://bafybeier5aygx7bnqnxtw4ijc36mcayo4yhcl6sqeqfansmm6letd27puq.ipfs.w3s.link/aaa.jpeg"
      },
      {
        name: "Syqem",
        songs: "Metal",
        url: "https://bafybeif34bfho76bv3bzrlu3r7vtzkbdyc75zzjtqae4lcigzd3ngmcv2i.ipfs.w3s.link/syqem.jpeg"
      },
      {
        name: "Vogelfrey",
        songs: "Mittelalter Metal",
        url: "https://bafybeih7anvzydf3lullqu35g5dmnkj44obqsstccdzqnkqznv5mjzorsa.ipfs.w3s.link/vf.jpg"
      },
      
    ],
  
    rappers: [
      {
        name: "Lil Uzi Vert",
        songs: "XO tour llif3, Baby Pluto, Patek",
        url: "https://m.media-amazon.com/images/I/51Yuxj1hKvL.jpg"
      },
      {
        name: "Jack Harlow",
        songs: "What's poppin, Tyler Herro, Hot Boy Bling",
        url:
          "https://townsquare.media/site/812/files/2020/08/jack-harlow-abcs.jpg?w=1200"
      },
      
      {
        name: "Future",
        songs: "Mask off, That's it, Low Life",
        url:
          "https://cdns-images.dzcdn.net/images/artist/b6cbe1b72cc372f17d6814f9cf5c7005/500x500.jpg"
      },
      {
        name: "Kid Laroi",
        songs: "Addison Rae, Tell Me Why, So Done",
        url: "https://f4.bcbits.com/img/0020989692_10.jpg"
      },
      {
        name: "juice Wrld",
        songs: "Lucid Dreams, Armed and Dangerous, Robbery",
        url: "https://image.mymixtapez.com/artists/30477/profile/medium"
      },
     
    ],
    pop: [
      {
        name: "Justin Bieber",
        songs: "Baby, Yummy, Intentions",
        url:
          "https://assets.teenvogue.com/photos/5f58d4e14111b77a6cb7f808/4:3/w_3015,h_2261,c_limit/GettyImages-1228408822.jpg"
      },
      {
        name: "Shawn Mendes",
        songs: "Stiches, Senorita, Treat you better",
        url:
          "https://thumbor.forbes.com/thumbor/fit-in/416x416/filters%3Aformat%28jpg%29/https%3A%2F%2Fspecials-images.forbesimg.com%2Fimageserve%2F5ed56f207fe4060006bbce9a%2F0x0.jpg%3Fbackground%3D000000%26cropX1%3D0%26cropX2%3D1080%26cropY1%3D0%26cropY2%3D1080"
      },
      {
        name: "Ed Sheeran",
        songs: "Shape of You, perfect, Galway Girl",
        url:
          "https://c4.wallpaperflare.com/wallpaper/855/883/777/4k-grammy-2018-ed-sheeran-photo-wallpaper-preview.jpg"
      }
    ],
    Country: [
      {
        name: "Lil Nas X",
        songs: "Old Town Road, Old Town Road(Remix), Panini",
        url:
          "https://e-cdns-images.dzcdn.net/images/artist/e645a215208b825df07277d80299758d/500x500.jpg"
      },
      {
        name: "Billy Ray cyrus",
        songs: "Butterfly Fly Away, Ready Set Don't go, Old Town Road(remix)",
        url:
          "https://i.pinimg.com/564x/41/96/69/419669a86ed03b912360a51d6aed26c3.jpg"
      }
    ]
  };
  
  export default artists;
  