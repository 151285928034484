// React imports
import React, { useEffect } from "react"
import { useState } from "react"

//Database import
import {db} from "./firebase"

//Firebase imports
import {collection, getDocs, query,limit, orderBy} from "firebase/firestore"




//CSS Import
import './Test.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//react component imports
import SideBar from "./SideBar"
import IconBar from "./IconBar"
import MusicPlayer from "./MusicPlayer"
import ProfileButton from "./ProfileButton";




const Saves = () => {

    //code snipped from wavesurfer as reference
  //setTrackInfo([songTitle, songArtist, songCover, waveSurfer.getDuration()])

  //state for musicplayer
  const [isPlaying, setIsPlaying] = useState(false)
  const [trackIndex, setTrackIndex] = useState(0)
  const [trackInfo, setTrackInfo] = useState(["--", "--", "https://firebasestorage.googleapis.com/v0/b/finntorben-f5af3.appspot.com/o/gray.png?alt=media&token=1d90004b-727f-497f-b3aa-f703e757b8d5", 0])
  const [trackProgress, setTrackProgress] = useState(0)
  const [trackProgressTarget, setTrackProgressTarget] = useState(0) //used for setting current track progress to a target value

  //event listener for switching isPlaying when the spacebar is pressed
  document.addEventListener("keyup", e => {
    if (e.code === "Space"){
      console.log("spacebarhit")
    }
  })
  // console.log(trackInfo)

  //state for in-app navigation
  const [navState, setNavState] = useState("Library")
  // const [viewAreaHeadline, setViewAreaHeadline] = useState("Library")

  //state for tags
  const [activeTags, setActiveTags] = useState([])

  //state for search queries
  const [searchQuery, setSearchQuery] = useState("")

  //state for musicdata from the database
  const [musicData, setMusicData] = useState([])

  //state for storing window height
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  //collecting data from database
  const musicCollectionRef = collection(db, "MazzeSongs")
  //queries to be used for retrieving data
  // const firstQuery = query(musicCollectionRef, orderBy("legacyId"), limit(Math.ceil(windowHeight / 90)))
  // const firstQuery = query(musicCollectionRef, orderBy("legacyId"), limit(50))
  const firstQuery = query(musicCollectionRef, orderBy("legacyId"), limit(100))

  //store the number of documents received from the database
  const [numOfDocs, setNumOfDocs] = useState(0)

  //check for the window beeing resized. data used to query size needed to fill the screen
  window.addEventListener("resize", () => {
    setWindowHeight(window.innerHeight)
  })

  //function for getting songs from the db
  const getMusic = async () => {
    // console.log("getting music")
    // let selectedQuery

    // if (numOfDocs === 0){

    //   console.log("first query")
    //   selectedQuery = firstQuery
      
    // } else {

    //   console.log("additional query")

    // }
    const data = await getDocs(firstQuery)
    //const data = await getDocs(selectedQuery)
    setMusicData(data.docs.map(doc => ({...doc.data(), id: doc.id})))
    setNumOfDocs(data.docs.length)
  }

  //calling the function on first load of the app
  useEffect(() => {
    getMusic()
  },[])

  // console.log("music data: ", musicData)

  const switchPlaying = () => {
    setIsPlaying(!isPlaying)
  }

  const switchPlayingFalse = () => {
    setIsPlaying(false)
  }

  const newTrackIndex = (index) => {
    setTrackIndex(index)
  }

  const waveformTriggerBridge = () => {
    setIsPlaying(false)
  }

  return (
    <div className="container-fluid gx-0 h-100">
      <div className="Mainframe row gx-0">
      <div className="IconBarContainer col-auto col-md-3">
          <IconBar />
        </div>
        <div className="SideBarContainer col-auto col-md-3">
          <SideBar numOfDocs={numOfDocs} searchQuery={searchQuery} setSearchQuery={setSearchQuery} musicData={musicData} activeTags={activeTags} setActiveTags={setActiveTags} navInput={setNavState} navState={navState}/>
        </div>
        <div className="ViewArea col">
          <div className="viewAreaHeader row">
            <div className="viewAreaHeadline col">
              <h2>Saves</h2>
              {/* <button onClick={() => getMusic()}>Query more!</button> */}
            </div>
            <div className="profileButtonContainer col-auto">
              <ProfileButton/>
            </div>
          </div>
         
        </div>
      </div>
      <MusicPlayer isPlaying={isPlaying} setIsPlaying={switchPlaying} trackIndex={trackIndex} setTrackIndex={newTrackIndex} trackProgress={trackProgress} setTrackProgress={setTrackProgress} setTrackProgressTarget={setTrackProgressTarget} trackInfo={trackInfo}/>
    </div>
  );
};

export default Saves