//react imports
import React, { useEffect } from "react"
import { useState } from "react";
import { Button, Dropdown, ToggleButton } from "react-bootstrap";
import ButtonGroup from 'react-bootstrap/ButtonGroup';

//icon import
import {TbFilter} from 'react-icons/tb'

function MusicFilter(props){

  // const [songsData, setSongs] = React.useState(SongsData)
  const [tempTagValues, setTempTagValues] = useState([])

  // states for speeds, moods & genres
  const [allSpeeds, setAllSpeeds] = React.useState([])
  const [allMoods, setAllMoods] = React.useState([])
  const [allGenres, setAllGenres] = React.useState([])
  const [allAlbums, setAllAlbums] = React.useState([])
  const [allArtists, setAllArtists] = React.useState([])
  const [productionQuality, setProductionQuality] = React.useState([])

  //take all needed musicData & add them into their respective states
  useEffect(() => {
    if (props.musicData.length > 0) {
      props.musicData.map(song => {
        if (song.id !== 0){
          if (!allSpeeds.includes(song.speed)){
            allSpeeds.push(song.speed)
          }
          if (!allMoods.includes(song.mood)){
            allMoods.push(song.mood)
          }
          if (!allGenres.includes(song.genre)){
            allGenres.push(song.genre)
          }
          if (!productionQuality.includes(song.productionQuality)){
            productionQuality.push(song.productionQuality)
          }
          if (!allAlbums.includes(song.album)){
            allAlbums.push(song.album)
          }
          if (!allArtists.includes(song.artist)){
            allArtists.push(song.artist)
          }
        }
      })
    }
  },[props.musicData])

  //function for switchting tags between acive & inactive
  const setTag = (tag, tagType) => {
    if (props.activeTags.length === 0){
      props.setActiveTags([...props.activeTags, {value: tag, type: tagType}])
    } else {
      const confirmationValue = tagType + " " + tag

      if(tempTagValues.includes(confirmationValue)){
        props.setActiveTags(props.activeTags.filter(oldTags => oldTags.value !== tag))
      } else {
        props.setActiveTags([...props.activeTags, {value: tag, type: tagType}])
      }
    }
  }

  //legacy code, still critical but exact use not investigated (lazy me)
  useEffect(() => {
    setTempTagValues(() => props.activeTags.map(tagsData => tagsData.type + " " + tagsData.value))
  }, [props.activeTags, props.numOfDocs])

  const generatedMoods = allMoods.map((mood, index) => {
    const confirmationValue = "mood " + mood
    return(
      <Dropdown.Item key={confirmationValue} onClick={() => setTag(mood, "mood")}><input onChange={() => true} checked={tempTagValues.includes(confirmationValue)} type="checkbox"></input>{mood}</Dropdown.Item>
    )
  })
  const generatedGenres = allGenres.map((genre, index) => {
    const confirmationValue = "genre " + genre
    return(
      <Dropdown.Item key={confirmationValue} onClick={() => setTag(genre, "genre")}><input onChange={() => true} checked={tempTagValues.includes(confirmationValue)} type="checkbox" ></input>{genre}</Dropdown.Item>
    )
  })


  const generatedProductionQualities = productionQuality.map(productionQuality =>{
    const confirmationValue = "productionQuality " + productionQuality
    return(
      <Dropdown.Item key={confirmationValue} onClick={() => setTag(productionQuality, "productionQuality")}><input onChange={() => true} checked={tempTagValues.includes(confirmationValue)} type="checkbox" ></input>{productionQuality}</Dropdown.Item>
    )
  })

  const albums = allAlbums.map(album =>{
    const confirmationValue = "album " + album
    return(
      <Dropdown.Item key={confirmationValue} onClick={() => setTag(album, "album")}><input onChange={() => true} checked={tempTagValues.includes(confirmationValue)} type="checkbox" ></input>{album}</Dropdown.Item>
    )
  })
  const artists = allArtists.map(artist =>{
    const confirmationValue = "artist " + artist
    return(
      <Dropdown.Item key={confirmationValue} onClick={() => setTag(artist, "artist")}><input onChange={() => true} checked={tempTagValues.includes(confirmationValue)} type="checkbox" ></input>{artist}</Dropdown.Item>
    )
  })

  return(
    <>
      <div className="filterHeadlineContainer">
        <TbFilter/>
        <h5>Filter:</h5>
      </div>
      <div className="btn-group flex-wrap musicFilter">
        <Dropdown autoClose="outside" as={ButtonGroup}>
          <Dropdown.Toggle className="dropBtn" variant="outline-primary" size="sm">
            Genre
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {generatedGenres}
          </Dropdown.Menu>
          </Dropdown>
          <Dropdown autoClose="outside" as={ButtonGroup}>
          <Dropdown.Toggle className="dropBtn" variant="outline-primary" size="sm">
          Mood
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {generatedMoods}
          </Dropdown.Menu>
        </Dropdown>
        {/* <Dropdown autoClose="outside" as={ButtonGroup}>
          <Dropdown.Toggle className="dropBtn" variant="outline-primary" size="sm">
          Quality
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {generatedProductionQualities}
          </Dropdown.Menu>
        </Dropdown> */}
        <Dropdown autoClose="outside" as={ButtonGroup}>
          <Dropdown.Toggle className="dropBtn" variant="outline-primary" size="sm">
          Artist
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {artists}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown autoClose="outside" as={ButtonGroup}>
          <Dropdown.Toggle className="dropBtn" variant="outline-primary" size="sm">
          Album
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {albums}
          </Dropdown.Menu>
        </Dropdown>
        <div className="musicFilter btn-group"></div>
      </div>
    </>
  )
}
export default MusicFilter

