import React, { useState, useRef } from "react";
import "./Artist.css";
import Artistgrid from "./Artistgrid";
import amboss1 from "../assets/icons/mw.jpg"


function Artist() {
  const [selectedGenre, setSelectedGenre] = useState("Rock");

  const setGenre = (genre) => {
    setSelectedGenre(genre);
  };
  
  const renderList = Artistgrid [selectedGenre].map((artist) => {
    return (
      <div className="Artistgrid flex">
        <div className="image">
          <img src={artist.url} alt={artist.name} />
        </div>
        <div className="content">
          <p className="name">{artist.name.toUpperCase()}</p>
          <p className="songs">{artist.songs}</p>
        </div>
      </div>
    );
  });
  const genres = Object.keys(Artistgrid).map((genre, id) => {
    return (
      <div className="pills">
        <span key={id} onClick={() => setGenre(genre)}>
          {genre.toUpperCase()}
        </span>
      </div>
    );
  });

  return (
   
    
    <div className="Apps1">
        <hr />
        <div className="container-pill">{genres}</div>
        <div className="container-artists">{renderList}</div>
      </div>

  );
}
export default Artist