
//bootstrap imports
import 'bootstrap/dist/css/bootstrap.min.css';

//react imports
import { useState, useEffect } from 'react';

//react router imports
import { useNavigate, useLocation } from "react-router-dom";

//home icons
import {HiOutlineHome} from "react-icons/hi";
import {HiHome} from "react-icons/hi";

//library icons
import { MdOutlineLibraryMusic } from "react-icons/md";
import { MdLibraryMusic } from "react-icons/md";

//artist icons
import {HiOutlineUserGroup} from "react-icons/hi";
import {HiUserGroup} from "react-icons/hi";

//favorite icons
import {MdOutlineFavoriteBorder} from "react-icons/md";
import {MdFavorite} from "react-icons/md";

//purchase icons
import { RiMoneyEuroCircleLine } from "react-icons/ri";
import { RiMoneyEuroCircleFill } from "react-icons/ri";


function IconBar(props){
  const [currentLocation, setCurrentLocation] = useState()

  let location = useLocation()

  useEffect(() => {
    console.log(location.pathname)
    setCurrentLocation(location.pathname)
  }, [location])

  const updateQuery = (event) => {
    props.setSearchQuery(event.target.value)
  }
  const navigate = useNavigate()
  const toDashboard = () => {
    navigate("/main")
  }
  const toHome1 = () => {
    navigate("/startseite")
  }
  const toHome = () => {
    navigate("/dashboard")
  }
  const toArtists = () => {
    navigate("/artists")
  }
  const toSaves = () => {
    navigate("/saves")
  }
  const toKaeufe = () => {
    navigate("/kaeufe")
  }

  return(
    <div className="IconBar">
      <div className='icon'>
        {currentLocation === "/startseite" ? <HiHome className="whiteGlow" size={32}/> : <HiOutlineHome size={32} onClick={toHome1}/>} 
      </div>
      <div className='icon'>
        {currentLocation === "/main" ? <MdLibraryMusic className="whiteGlow" size={32}/> : <MdOutlineLibraryMusic size={32} onClick={toDashboard}/>}
      </div>
      <div className='icon'>
        {currentLocation === "/Artist" ? <HiUserGroup className="whiteGlow" size={32}/> : <HiOutlineUserGroup size={32} onClick={toArtists}/>}
      </div>
      <div className='icon'>
        {currentLocation === "/saves" ? <MdFavorite className="whiteGlow" size={32}/> : <MdOutlineFavoriteBorder size={32} onClick={toSaves}/>}
      </div>
      <div className='icon'>
        {currentLocation === "/kaeufe" ? <RiMoneyEuroCircleFill className="whiteGlow" size={32}/> : <RiMoneyEuroCircleLine size={32} onClick={toKaeufe}/>}
      </div>
      {/* <RiLogoutCircleRFill style={{ marginTop: 25, marginLeft: 10 }} size={30} color="white" items-align="center" onClick={toHome} /> */}
    </div>
  )
}
export default IconBar
