//react component import
import MusicFilter from "./MusicFilter";

//bootstrap imports
import 'bootstrap/dist/css/bootstrap.min.css';

//Asset import
import platformLogo from "../assets/icons/favicone.png"
import amboss from "../assets/icons/amboss.png"


//icon import
import {BiSearchAlt} from 'react-icons/bi'

function SideBar(props){

  const updateQuery = (event) => {
    props.setSearchQuery(event.target.value)
  }

  return(
    <div className="SideBar container">
      <div className="Branding row">
        <div className="brandlogo col">
          <img src={platformLogo}></img>
          </div>
          <div className="brandname col">
          <h4>Unique Music Group</h4>
        </div>
          
        
       </div>

      <div className="ControlPanel container">
      <div className="BrowseSideBarContent">
          <div className="titelsuche row">
            <div className="SearchContainer">
              <BiSearchAlt/><h5>Titelsuche</h5>
            </div>
            <div className="col">
              <input className="textSearch" value={props.searchQuery} onInput={updateQuery} type={"text"} placeholder={"Suche nach Titel"}></input>
            </div>
          </div>
          <MusicFilter numOfDocs={props.numOfDocs} musicData={props.musicData} activeTags={props.activeTags} setActiveTags={props.setActiveTags}/>
        </div>
      </div>
    </div>
  )
}
export default SideBar
