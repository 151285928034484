//react import
import React from "react"
import { useState, useEffect, useRef } from "react"

//Asset imports
import PlayerSkipLinks from "../assets/icons/PlayerSkipLinks.png"
import PlayerPlay from "../assets/icons/PlayerPlay.png"
import PlayerSkipRechts from "../assets/icons/PlayerSkipRechts.png"
import pausebutton from "../assets/icons/pause.png"
// import VolumeButton from "./data/MusicControl/VolumeButton.png" // deactivated for light version


//bottomscreen Music Controls
function MusicPlayer(props){

  const [isScrubbing, setIsScrubbing] = useState(false)
  const [scrubValue, setScrubValue] = useState(0)

  const currentPercentage = props.trackInfo[3] ? `${(props.trackProgress / props.trackInfo[3]) * 100}` : '0';

  // compensates for the 'thumb' of the range slider (progress view)  width, which causes the thumb to be off center of itself, in ordner not to go over the range slider boundary
  const compensatedCurrentPercentage = currentPercentage === "100" ? "100" : (props.trackInfo[3] ? currentPercentage - (((currentPercentage - 50) * 2) / 150) : '0')

  // const trackStyling = `linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)`;
  const trackStyling = `-webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${compensatedCurrentPercentage}%, #fff), color-stop(${compensatedCurrentPercentage}%, #777))`;

  let readableDurationSeconds = parseInt(props.trackInfo[3] % 60)
  let readableDurationMinutes = parseInt((props.trackInfo[3] / 60) % 60)

  const readableCurrentSeconds = parseInt(props.trackProgress % 60)
  const readableCurrentMinutes = parseInt((props.trackProgress / 60) % 60)

  if (isNaN(readableDurationSeconds)){
    readableDurationSeconds = "--"
    readableDurationMinutes = "--"
  }

  const playPause = () => {
    if (props.trackInfo[0] !== "--") {
      props.setIsPlaying(!props.isPlaying)
    }
  }

  const onScrub = (value) => {
    setIsScrubbing(true)
    setScrubValue(value)
  }

  const onScrubEnd = () => {
    setIsScrubbing(false)
    props.setTrackProgress(scrubValue)
    props.setTrackProgressTarget(scrubValue)
  }

  //code snipped from wavesurfer as reference
  //setTrackInfo([songTitle, songArtist, songCover, waveSurfer.getDuration()])
  return(
      <div className="musicControl d-flex row gx-0">
        <div className="TitleInfo col-3 col-md-auto">
          <img alt="coverart" src={props.trackInfo[2]}></img> 
          <div className="container h-100">
            <div className="MusicControlSongNameContainer row h-50">
              <h4 className="title">{props.trackInfo[0]}</h4>
            </div>
            <div className="MusicControlArtistNameContainer row h-50">
              <h5 className="artist">{props.trackInfo[1]}</h5>
            </div>
          </div>
        </div>
        <div className="PlayControl col-auto order-2">
          <div className="PlayControlButtons">
            <img alt="prev button" src={PlayerSkipLinks}></img>
            {/* <img alt="play button" onClick={()=>console.log("WRONG CODE, remember?")} src={PlayerPlay}></img> */}
            {props.isPlaying ? <img alt="pause button" onClick={playPause} src={pausebutton}></img> : <img alt="play button" onClick={playPause} src={PlayerPlay}></img>}
            <img alt="skip button" src={PlayerSkipRechts}></img>
          </div>
          {/* <div className="VolumeButtonBackground">
            <img src={VolumeButton}></img>
          </div> */}
        </div>
        <div className="MusicProgressBarContainer col-8 offset-md-0 col-md order-md-1 order-3">
          {readableCurrentSeconds < 10 ? <p>{readableCurrentMinutes}:0{readableCurrentSeconds}</p> : <p>{readableCurrentMinutes}:{readableCurrentSeconds}</p>}
          {/* <p>--:--</p> */}
          <input
            type="range"
            value={isScrubbing ? scrubValue : props.trackProgress}
            step=".1"
            min="0"
            max={props.trackInfo[3]}
            className="progress"
            onChange={(e) => onScrub(e.target.value)}
            onMouseUp={onScrubEnd}
            onKeyUp={onScrubEnd}
            style={{ background: trackStyling }}
          />
          {/* <div className="MusicProgressBarBase">
            <div className="MusicProgressBarProgress"></div>
          </div> */}
          {readableDurationSeconds < 10 ? <p>{readableDurationMinutes}:0{readableDurationSeconds}</p> : <p>{readableDurationMinutes}:{readableDurationSeconds}</p>}
        </div>
      </div>
  )
}
export default MusicPlayer

