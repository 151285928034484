// React imports
import React, { useEffect } from "react"
import { useState } from "react"

//Database import
import {db} from "./components/firebase"

//Firebase imports
import {collection, getDocs, query, endAt, startAfter, where, limit, orderBy} from "firebase/firestore"

//Routing Import
import { MemoryRouter as Router, Routes, Route } from 'react-router-dom';

//CSS Import
import './App.css';
import './AppMobile.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//react component imports
import SideBar from "./components/SideBar"
import IconBar from "./components/IconBar"
import MusicGrid from "./components/MusicGrid"
import MusicPlayer from "./components/MusicPlayer"
import ProfileButton from "./components/ProfileButton";

//imports for login & authentication
import Login  from "./components/Login"
import Register  from "./components/Register"
import Reset  from "./components/Reset"
import Dashboard  from "./components/Dashboard"
import Startseite  from "./components/startseite"
import Artists  from "./components/artists"
import Saves  from "./components/saves"
import Kaeufe  from "./components/kaeufe"
import { event } from "jquery";


const Main = () => {

    //code snipped from wavesurfer as reference
  //setTrackInfo([songTitle, songArtist, songCover, waveSurfer.getDuration()])

  //state for musicplayer
  const [isPlaying, setIsPlaying] = useState(false)
  const [trackIndex, setTrackIndex] = useState(0)
  const [trackInfo, setTrackInfo] = useState(["--", "--", "https://firebasestorage.googleapis.com/v0/b/finntorben-f5af3.appspot.com/o/gray.png?alt=media&token=1d90004b-727f-497f-b3aa-f703e757b8d5", 0])
  const [trackProgress, setTrackProgress] = useState(0)
  const [trackProgressTarget, setTrackProgressTarget] = useState(0) //used for setting current track progress to a target value

  //event listener for switching isPlaying when the spacebar is pressed
  document.addEventListener("keyup", e => {
    if (e.code === "Space"){
      console.log("spacebarhit")
    }
  })
  // console.log(trackInfo)

  //state for in-app navigation
  const [navState, setNavState] = useState("Library")
  // const [viewAreaHeadline, setViewAreaHeadline] = useState("Library")

  //state for tags
  const [activeTags, setActiveTags] = useState([])

  //state for search queries
  const [searchQuery, setSearchQuery] = useState("")

  //state for musicdata from the database
  const [musicData, setMusicData] = useState([])

  //state for storing window height
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  //collecting data from database
  const musicCollectionRef = collection(db, "MazzeSongs")

  //store last document received from db
  const [latestDoc, setLatestDoc] = useState(null)

  //queries to be used for retrieving data
  const firstQuery = query(musicCollectionRef, orderBy("legacyId"), startAfter(latestDoc || 0), limit(Math.ceil(windowHeight / 90)))
  // const firstQuery = query(musicCollectionRef, orderBy("legacyId"), limit(50))
  // const firstQuery = query(musicCollectionRef, orderBy("legacyId"), limit(100))

  const [preventDBRequests, setPreventDBRequests] = useState(false)

  //store the number of documents received from the database
  const [numOfDocs, setNumOfDocs] = useState(0)

  //check for the window beeing resized. data used to query size needed to fill the screen
  window.addEventListener("resize", () => {
    setWindowHeight(window.innerHeight)
  })

  //function for getting songs from the db
  const getMusic = async () => {

    const data = await getDocs(firstQuery)
    //const data = await getDocs(selectedQuery)
    if (musicData.length > 0){
      const newArr = [...musicData, ...data.docs.map(doc => ({...doc.data(), id: doc.id}))]
      setMusicData(newArr)
    } else {
      setMusicData(data.docs.map(doc => ({...doc.data(), id: doc.id})))
    }
    
    setNumOfDocs(data.docs.length)

    //update latest doc
    setLatestDoc(data.docs[data.docs.length - 1])

    //trigger for end of database
    if(data.empty){
      setPreventDBRequests(true)
    }
  }

  const viewAreaContent = document.querySelector(".viewAreaContent")

  const handleScroll = () => {
    // console.log(viewAreaContent.offsetHeight)
    let triggerHeight = viewAreaContent.scrollTop + viewAreaContent.offsetHeight; 
    if (triggerHeight >= (viewAreaContent.scrollHeight - 1) && !preventDBRequests) {
      getMusic()
    }
  }

  //calling the function on first load of the app
  useEffect(() => {
    getMusic()
  },[])

  // console.log("music data: ", musicData)

  const switchPlaying = () => {
    setIsPlaying(!isPlaying)
  }

  const switchPlayingFalse = () => {
    setIsPlaying(false)
  }

  const newTrackIndex = (index) => {
    setTrackIndex(index)
  }

  const waveformTriggerBridge = () => {
    setIsPlaying(false)
  }

  return (
    <div className="container-fluid gx-0 h-100">
      <div className="Mainframe row gx-0">
      <div className="IconBarContainer col-auto col-md-3">
          <IconBar />
        </div>
        <div className="SideBarContainer col-auto col-md-3">
          <SideBar numOfDocs={numOfDocs} searchQuery={searchQuery} setSearchQuery={setSearchQuery} musicData={musicData} activeTags={activeTags} setActiveTags={setActiveTags} navInput={setNavState} navState={navState}/>
        </div>
        <div className="ViewArea col">
          <div className="viewAreaHeader row">
            <div className="viewAreaHeadline col">
              <h2>Library</h2>
              {/* <button onClick={() => getMusic()}>Query more!</button> */}
            </div>
            <div className="profileButtonContainer col-auto">
              <ProfileButton/>
            </div>
          </div>
          <div className="viewAreaContent row" onScroll={handleScroll}>
            <MusicGrid waveformTriggerBridge={waveformTriggerBridge} musicData={musicData} searchQuery={searchQuery} activeTags={activeTags} isPlaying={isPlaying} setIsPlaying={switchPlaying} setIsPlayingFalse={switchPlayingFalse} trackIndex={trackIndex} setTrackIndex={newTrackIndex} setTrackProgress={setTrackProgress} trackProgressTarget={trackProgressTarget} setTrackInfo={setTrackInfo} trackInfo={trackInfo}/>
          </div>
        </div>
      </div>
      <MusicPlayer isPlaying={isPlaying} setIsPlaying={switchPlaying} trackIndex={trackIndex} setTrackIndex={newTrackIndex} trackProgress={trackProgress} setTrackProgress={setTrackProgress} setTrackProgressTarget={setTrackProgressTarget} trackInfo={trackInfo}/>
    </div>
  );
};

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/main" element={<Main />} />

        <Route exact path="/" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/reset" element={<Reset />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/startseite" element={<Startseite />} />
        <Route exact path="/artists" element={<Artists />} />
        <Route exact path="/saves" element={<Saves />} />
        <Route exact path="/kaeufe" element={<Kaeufe />} />
      </Routes>
    </Router>
  );
}
