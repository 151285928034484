//react imports
import React, { useEffect, useState } from 'react'

//bootstrap imports
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import SplitButton from 'react-bootstrap/SplitButton';

function LicenseViewModal({isVisible, setIsVisible, modalData}) {

    const [showModal, setShowModal] = useState(false)
    const [selectedPriceGroup, setSelectedPriceGroup] = useState(0)
    const [displayText, setDisplayText] = useState({
        Name: "Undefined",
        Beschreibung: "Undefined",
        Preis: 999
    })

    useEffect(() => {
        setShowModal(isVisible)
        if (isVisible === true){
            setDisplayText({
                Name: modalData[0].Name,
                Beschreibung: modalData[0].Beschreibung,
                Preis: modalData[0].Preis
            })
        }
    }, [isVisible])

    useEffect(() => {
        if (modalData[0] !== undefined){
            setDisplayText({
                Name: modalData[selectedPriceGroup].Name,
                Beschreibung: modalData[selectedPriceGroup].Beschreibung,
                Preis: modalData[selectedPriceGroup].Preis
            })
        }
    }, [selectedPriceGroup])
    
    const handleClose = () => {
        setShowModal(false)
        setIsVisible(false)
    }

    const dropdownRender = modalData.map((data, index) => {
        if(modalData[0] !== undefined){
            return(
                <Dropdown.Item as="button"><div onClick={() => {setSelectedPriceGroup(index)}}>{data.Name}</div></Dropdown.Item>
            )
        }
    })

    return (
        <div>
            <Modal className="my-modal" class="modal-dialog modal-lg" show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='pricingModalTitle'>
                        {displayText.Name}
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-secondary" title={"Preisstufe wählen"} id="split-button-pull-right">Preisstufe wählen</Dropdown.Toggle >
                            <Dropdown.Menu>
                                {dropdownRender}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{displayText.Beschreibung}</p>
                    <h5>Preis:  {displayText.Preis}€</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>Kaufen!</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default LicenseViewModal