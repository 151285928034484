import React from "react";
import ReactSlidy from 'react-slidy'
import 'react-slidy/lib/styles.css'
import dd from "../assets/icons/dd.jpg"
import lucifer from "../assets/Images/lucifer.jpg"

const Slider = () => {
  return (
<div className="Hallo">
<ReactSlidy>
  <img src={lucifer} />
  <img src={dd}/>
  
</ReactSlidy>
</div>
  )
  }
export default Slider;

